<template>
  <div class="main-div">
    <el-container>
      <el-header height="40px">{{ showTitle }}<span style="float:right">{{ showStatus }}</span></el-header>
      <el-main>
        <el-tabs v-model="activeTab">
          <el-tab-pane label="详情" name="detail">
            <!-- 详情页签 -->
            <el-form ref="form" :model="form" :rules="rules" label-width="90px" class="base-form">
              <el-row :gutter="10">
                <el-col :md="10" :sm="24" :xs="24">
                  <el-form-item :label="!form.isgroup?'客人姓名':'团队名称'" prop="name">
                    <el-input ref="nameinput" v-model="form.name" maxlength="60" @blur="checkNameExist">
                      <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="showProfileDialog=true"/>
                      <template slot="append">
                        <el-tooltip class="item" effect="dark" content="团队" placement="top-start">
                          <el-checkbox v-model="form.isgroup"/>
                        </el-tooltip>
                      </template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="7" :sm="12" :xs="24">
                  <el-form-item label="电话号码">
                    <el-input v-model="form.telephone" maxlength="20" clearable/>
                  </el-form-item>
                </el-col>
                <el-col :md="7" :sm="12" :xs="24">
                  <el-form-item label="证件号码">
                    <el-input v-model="form.idnumber" maxlength="20" clearable/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :md="10" :sm="24" :xs="24">
                  <el-form-item :label="showDays" prop="arrdate">
                    <el-date-picker v-model="dateselect" type="daterange" align="right" unlink-panels
                                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                    :picker-options="pickerOptions" value-format="yyyy-MM-dd" :clearable="false"/>
                  </el-form-item>
                </el-col>
                <el-col :md="7" :sm="12" :xs="24">
                  <el-form-item label="渠道" prop="channelsource">
                    <el-select v-model="form.channelsource" placeholder="请选择渠道" clearable filterable>
                      <el-option v-for="item in subchannels" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                        <span style="float: left">{{ item.code }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :md="7" :sm="12" :xs="24">
                  <el-form-item label="预定类型">
                    <el-select v-model="form.restype" placeholder="请选择预定类型" clearable filterable>
                      <el-option v-for="item in restypes" :key="item.code" :label="item.desc" :value="item.code">
                        <span style="float: left">{{ item.code }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :md="10" :sm="8" :xs="24">
                  <el-form-item label="锁房">
                    <el-select v-model="form.block" placeholder="请选择锁房" filterable>
                      <el-option v-for="item in blocks" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                        <span style="float: left">{{ item.code }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :md="7" :sm="8" :xs="24">
                  <el-form-item label="价格代码">
                    <el-select v-model="form.ratecode" placeholder="请选择价格代码" clearable filterable>
                      <el-option v-for="item in ratecodes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                        <span style="float: left">{{ item.code }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :md="7" :sm="8" :xs="24">
                  <el-form-item label="付款方式">
                    <el-select v-model="form.payment" placeholder="请选择付款方式" clearable filterable>
                      <el-option v-for="item in payments" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                        <span style="float: left">{{ item.code }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :md="10" :sm="12" :xs="24">
                  <el-form-item label="估价/预付">
                    <el-popover placement="bottom-start" title="估价详情" width="200" trigger="hover">
                      <p>客房价格: {{ form.roomprice }}</p>
                      <p>票务价格: {{ form.ticketprice }}</p>
                      <p>餐饮价格: {{ form.cateringprice }}</p>
                      <p>会议价格: {{ form.meetingprice }}</p>
                      <p>导游价格: {{ form.guideprice }}</p>
                      <p>SPA价格: {{ form.spaprice }}</p>
                      <p>车船价格: {{ form.boatprice }}</p>
                      <p>自定义项目价格: {{ form.enterprice }}</p>
                      <el-input slot="reference" v-model="showPrice" style="width: 50%" readonly>
                        <i slot="suffix" class="el-input__icon el-icon-view"/>
                      </el-input>
                    </el-popover>
                    <el-input v-model="form.deposit" style="width: 45%; padding-left: 10px" readonly/>
                  </el-form-item>
                </el-col>
                <el-col :md="7" :sm="12" :xs="24">
                  <el-form-item label="市场">
                    <el-select v-model="form.market" placeholder="请选择市场" clearable filterable>
                      <el-option v-for="item in markets" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                        <span style="float: left">{{ item.code }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :md="7" :sm="12" :xs="24">
                  <el-form-item label="客源">
                    <el-select v-model="form.scode" placeholder="请选择客源" clearable filterable>
                      <el-option v-for="item in scodes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                        <span style="float: left">{{ item.code }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :md="10" :sm="12" :xs="24">
                  <el-form-item label="套餐/份数">
                    <el-select v-model="form.packagecode" style="width: 50%">
                      <el-option v-for="item in kits" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                        <span style="float: left">{{ item.code }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                      </el-option>
                    </el-select>
                    <el-input v-model="form.packagenum" style="width: 20%; padding-left: 10px" readonly/>
                    核销
                    <el-input v-model="form.checkednum" style="width: 20%;" readonly/>
                  </el-form-item>
                </el-col>
                <el-col :md="7" :sm="12" :xs="24">
                  <el-form-item label="创建信息">
                    <el-input v-model="createDateTime" readonly/>
                  </el-form-item>
                </el-col>
                <el-col :md="7" :sm="12" :xs="24">
                  <el-form-item label="取消信息">
                    <el-input v-model="cancelDateTime" readonly/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :md="24" :sm="24" :xs="24">
                  <el-form-item label="备注">
                    <el-input v-model="form.info" type="textarea" :rows="4" placeholder="请输入备注内容"/>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <!-- 预付款 -->
          <el-tab-pane name="prepay" :disabled="form.sqlid<=0">
            <span slot="label"><i v-show="hasSubValue('prepay')" class="el-icon-star-on"/> 预付款</span>
            <ColPay v-if="activeTab=='prepay'" ref="paylist" :history="history" :colno="form.colno" :custom="'COL'" :summary="'COL'" @refreshCol="refresh"/>
          </el-tab-pane>
          <!-- 客房 -->
          <el-tab-pane name="room" :disabled="form.sqlid<=0">
            <span slot="label"><i v-show="hasSubValue('room')" class="el-icon-star-on"/> 客房</span>
            <ColRoom v-if="activeTab=='room'" ref="roomlist" :history="history" :colno="form.colno" :custom="'COL'" :summary="'COL'" @refreshCol="refresh"/>
          </el-tab-pane>
          <!-- 票务 -->
          <el-tab-pane name="ticket" :disabled="form.sqlid<=0">
            <span slot="label"><i v-show="hasSubValue('ticket')" class="el-icon-star-on"/> 票务</span>
            <ColTicket v-if="activeTab=='ticket'" ref="ticketlist" :qrcodeurl="form.ticketqrcode" :history="history" :colno="form.colno" :custom="'COL'" :summary="'COL'" @refreshCol="refresh"/>
          </el-tab-pane>
          <!-- 餐饮 -->
          <el-tab-pane name="catering" :disabled="form.sqlid<=0">
            <span slot="label"><i v-show="hasSubValue('catering')" class="el-icon-star-on"/> 餐饮</span>
            <ColCater v-if="activeTab=='catering'" ref="cateringlist" :history="history" :colno="form.colno" :custom="'COL'" :summary="'COL'" @refreshCol="refresh"/>
          </el-tab-pane>
          <!-- 会议 -->
          <el-tab-pane name="meeting" :disabled="form.sqlid<=0">
            <span slot="label"><i v-show="hasSubValue('meeting')" class="el-icon-star-on"/> 会议</span>
            <ColMeeting v-if="activeTab=='meeting'" ref="meetinglist" :history="history" :colno="form.colno" :custom="'COL'" :summary="'COL'" @refreshCol="refresh"/>
          </el-tab-pane>
          <!-- 导游 -->
          <el-tab-pane name="guide" :disabled="form.sqlid<=0">
            <span slot="label"><i v-show="hasSubValue('guide')" class="el-icon-star-on"/> 导游</span>
            <ColGuide v-if="activeTab=='guide'" ref="guidelist" :history="history" :colno="form.colno" :custom="'COL'" :summary="'COL'" @refreshCol="refresh"/>
          </el-tab-pane>
          <!-- SPA -->
          <el-tab-pane name="spa" :disabled="form.sqlid<=0">
            <span slot="label"><i v-show="hasSubValue('spa')" class="el-icon-star-on"/> SPA</span>
            <ColSpa v-if="activeTab=='spa'" ref="spalist" :history="history" :colno="form.colno" :custom="'COL'" :summary="'COL'" @refreshCol="refresh"/>
          </el-tab-pane>
          <!-- 车船 -->
          <el-tab-pane name="boat" :disabled="form.sqlid<=0">
            <span slot="label"><i v-show="hasSubValue('boat')" class="el-icon-star-on"/> 车船</span>
            <ColBoat v-if="activeTab=='boat'" ref="boatlist" :history="history" :colno="form.colno" :custom="'COL'" :summary="'COL'" @refreshCol="refresh"/>
          </el-tab-pane>
          <!-- 自定义项 -->
          <el-tab-pane name="fixcharge" :disabled="form.sqlid<=0">
            <span slot="label"><i v-show="hasSubValue('fixcharge')" class="el-icon-star-on"/> 自定义项</span>
            <Fixcharge v-if="activeTab=='fixcharge'" ref="fixchargelist" :colno="form.colno" :history="history" @refreshCol="refresh"/>
          </el-tab-pane>
          <!-- 折扣 -->
          <el-tab-pane name="discount" :disabled="form.sqlid<=0">
            <span slot="label"><i v-show="hasSubValue('discount')" class="el-icon-star-on"/> 折扣</span>
            <Discount v-if="activeTab=='discount'" ref="discountlist" :colno="form.colno"/>
          </el-tab-pane>
        </el-tabs>
      </el-main>
      <el-footer height="40px">
        <el-button type="primary" icon="el-icon-edit-outline" :disabled="form.status==='C'||form.status==='F'||history"
                   :loading="buttonLoading" @click="handleSave">保存</el-button>
        <el-button type="success" icon="el-icon-s-claim" :disabled="form.sqlid<=0||history||form.status==='C'||form.status==='F'||form.packagecode===''||form.hasroom"
                   @click="handleChecked">核销</el-button>
        <el-button type="danger" icon="el-icon-warning-outline" :disabled="form.sqlid<=0||form.status==='C'||form.status==='F'||form.status==='P'||history"
                   :loading="buttonLoading" @click="handleCancel">取消</el-button>
        <el-button type="primary" icon="el-icon-s-claim" :disabled="form.sqlid<=0||history"
                   :loading="buttonLoading" @click="handlePrepare">修复</el-button>
        <el-button type="primary" icon="el-icon-chat-line-square" @click="showMsgDialog=true">短信</el-button>
        <el-button type="primary" icon="el-icon-notebook-2" style="margin-left: 10px" :disabled="form.sqlid<=0" @click="showAccountDialog = true">账单</el-button>
        <el-dropdown style="margin-left: 10px" @command="handleCommand">
          <el-button type="primary" icon="el-icon-tickets">日志<i class="el-icon-arrow-down el-icon--right"/></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="U">用户日志</el-dropdown-item>
            <el-dropdown-item command="I">接口日志</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-footer>
    </el-container>
    <!-- 窗口Dialog -->
    <el-dialog v-dialogDrag title="选择档案" :visible.sync="showProfileDialog" :close-on-click-modal="false" width="85%" append-to-body>
      <ProfileSelect :profileid="form.profileid" :name="form.name" :telno="form.telephone" :type="form.isgroup?['G']:['']" :visible.sync="showProfileDialog" @selectValue="selectProfile"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <el-dialog v-dialogDrag title="查看日志" :visible.sync="showLogDialog" :close-on-click-modal="false" width="70%" top="7vh" append-to-body>
      <UserLogs ref="userlogs" :type="'COL'" :bookingid="form.colno" :visible.sync="showLogDialog"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <el-dialog v-dialogDrag title="查看接口日志" :visible.sync="showInterfaceLogDialog" :close-on-click-modal="false" width="70%" top="7vh" append-to-body>
      <OrderLogs :crsno="form.colno" :otano="form.channelno" :pmsno="form.pmscolno" :visible.sync="showInterfaceLogDialog"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <el-dialog v-dialogDrag title="短信" :visible.sync="showMsgDialog" :close-on-click-modal="false" width="70%" top="7vh" append-to-body>
      <MsgView :id="form.sqlid" :regno="form.colno" :type="'Z'" :mobile="form.telephone" :visible.sync="showMsgDialog"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <!--
    <el-dialog title="综合预定接口" :visible.sync="showIntefaceDialog" :close-on-click-modal="false" width="70%" append-to-body>
      <InterfaceOp :id="form.colno" :pmsno="form.pmscolno" :otano="form.channelno" :type="'COL'"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    -->
    <el-dialog v-dialogDrag title="账单详情" :visible.sync="showAccountDialog" :close-on-click-modal="false" width="70%" top="7vh" append-to-body @opened="getAccountData">
      <el-table v-loading="loadingAccount" :data="accountData" style="width: 100%" max-height="500"
                :summary-method="tableSummary" show-summary border>
        <el-table-column prop="type" label="类型" min-width="80"/>
        <el-table-column prop="date" label="日期" min-width="100"/>
        <el-table-column prop="departmentCode" label="账项代码" min-width="100"/>
        <el-table-column prop="text" label="描述" min-width="120"/>
        <el-table-column prop="amount" label="金额" min-width="100"/>
        <el-table-column prop="isroomfee" label="是否房费" min-width="100" :formatter="roomfeeFormatter"/>
      </el-table>
    </el-dialog>

    <el-dialog v-dialogDrag :visible.sync="showCancelDialog" width="40%" title="取消综合预定" append-to-body :close-on-click-modal="false">
      <el-form :model="cancelForm" label-width="auto">
        <el-form-item label="取消理由">
          <el-input v-model="cancelForm.cancelReason" clearable/>
        </el-form-item>
        <el-form-item label="系统取消规则">
          <el-switch v-model="cancelForm.calcRefund" active-color="#13ce66" inactive-color="#ff4949"/>
        </el-form-item>
        <el-form-item v-show="!cancelForm.calcRefund" label="扣款百分比">
          <el-input-number v-model="cancelForm.percent" :min="0"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="buttonLoading" @click="doCancel">确定</el-button>
        <el-button @click="showCancelDialog=false">退出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {load_col, save_col, cancel_col, refresh_col, finish_col, load_account} from '@/api/order/col';
  import {name_exist} from '@/api/profile/profile';
  import {stringDateDiff} from '@/utils/calcdate';
  import {select_data} from '@/api/common/custom-data';
  import {select_res, filterOnlyValue} from "@/utils/select_data_res";
  import {getColStatus} from '@/utils/status-format';
  import '@/styles/pagecontainer.scss';
  import ProfileSelect from "@/views/common/profile-select";
  import ColPay from "@/views/order/col/colpay";
  import ColTicket from '@/views/order/col/colticket';
  import ColCater from '@/views/order/col/colcater';
  import UserLogs from "@/views/common/userlogs";
  import OrderLogs from "@/views/common/orderlogs";
  import MsgView from "@/views/common/msgview";
  import Fixcharge from '@/views/order/col/fixcharge';
  import Discount from '@/views/order/col/discount';
  import ColRoom from '@/views/order/col/colroom';
  import ColMeeting  from "@/views/order/col/colmeeting";
  import ColGuide  from "@/views/order/col/colguide";
  import ColSpa  from "@/views/order/col/colspa";
  import ColBoat  from "@/views/order/col/colboat";

  export default {
    name:'ColDetail',
    components: {ProfileSelect,ColPay,ColTicket,UserLogs,OrderLogs,MsgView,
      ColCater,Fixcharge,Discount,
      ColMeeting,ColGuide,ColSpa,ColBoat,ColRoom},
    props: {
      colid:{ type: Number,default: 0},
      colno:{ type: String,default: ""},
      history:{type: Boolean,default: false},
      visible:{type: Boolean,default: true},
      isgroup: {type: Boolean, default: false}
    },
    data() {
      return {
        form:{},
        pickerOptions: {
          disabledDate(time) { return time.getTime() < Date.now() - 8.64e7; }
        },
        rules: {
          name: [{ required: true, message: '请输入名称', trigger: 'change' }],
          // block: [{ required: true, message: '请选择锁房', trigger: 'change' }],
          // channel: [{ required: true, message: '请选择渠道', trigger: 'change' }],
          channelsource: [{ required: true, message: '请选择渠道', trigger: 'change' }],
          // ratecode: [{ required: true, message: '请选择价格代码', trigger: 'change' }],
          arrdate: [{ required: true, message: '请选择日期', trigger: 'change' }]
        },
        subchannels:[],ratecodes:[],payments:[],blocks:[],markets:[],scodes:[],restypes:[],kits:[],orgkits:[],
        prepayTabelData: [], loadprepay:false,
        activeTab: 'detail',
        showProfileDialog: false, loading:false,showLogDialog:false,showMsgDialog:false,showIntefaceDialog:false,
        // showTicketDetailDialog:false,showPayDetailDialog:false,showCateringDetailDialog:false,showRoomDetailDialog:false,
        showInterfaceLogDialog:false,showAccountDialog:false, loadingAccount:false, accountData:[], buttonLoading:false,
        // showMeetingDetailDialog:false,showGuideDetailDialog:false,showSpaDetailDialog:false,showBoatDetailDialog:false,
        showCancelDialog:false,
        cancelForm:{
          sqlid:0,cancelReason:'',calcRefund:false,percent:0
        }
      }
    },
    computed:{
      menu_text() {
        return this.isgroup ? "团队预订" : "综合预订"
      },
      showTitle(){ return this.form.sqlid!=null&&this.form.sqlid>0? '编辑' + this.menu_text + ' 订单号: '+this.form.colno+
              (this.form.channelno.length>0?' OTA订单号: '+this.form.channelno:'')+
              (this.form.pmscolno.length>0?' 线下订单号: '+this.form.pmscolno: '') : '新建' + this.menu_text},
      showStatus(){ return this.form.sqlid!=null&&this.form.sqlid>0?getColStatus(this.form.status,this.form.pstatus):''},
      hasSubValue(){
        return function(type){
          if(type=='prepay'){
            return this.form.deposit>0;
          }else if(type=="room"){
            return this.form.hasroom;
          }else if(type=="ticket"){
            return this.form.hasticket;
          }else if(type=="catering"){
            return this.form.hascatering;
          }else if(type=="meeting"){
            return this.form.hasmeeting;
          }else if(type=="guide"){
            return this.form.hasguide;
          }else if(type=="spa"){
            return this.form.hasspa;
          }else if(type=="boat"){
            return this.form.hasboat;
          }else if(type=="fixcharge"){
            return this.form.hasentertainment;
          }else if(type=="discount"){
            return this.form.discountserialno!=null&&this.form.discountserialno.length>0;
          }
          return false;
        }
      },
      dateselect:{
        get:function(){
          return this.form.arrdate!=null && this.form.enddate!=null?[this.form.arrdate,this.form.enddate]:[];
        },
        set:function(dateValue){
          this.form.arrdate = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
          this.form.enddate = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
        }
      },
      createDateTime:{
        get:function(){
          return this.form.createdate==null||this.form.createdate=='1900-01-01'?''
                  :this.form.createdate+' '+this.form.createtime+ ' '+this.form.creator;
        }
      },
      cancelDateTime:{
        get:function(){
          return this.form.canceldate==null||this.form.canceldate=='1900-01-01'?''
                  :this.form.canceldate+' '+this.form.canceltime+' '+this.form.modify;
        }
      },
      showDays: function(){
        return '在店'+stringDateDiff(this.form.enddate,this.form.arrdate)+'天';
      },
      showPrice: function(){
        return this.form.roomprice+this.form.ticketprice+this.form.cateringprice+
                this.form.meetingprice+this.form.enterprice+this.form.guideprice+this.form.spaprice+this.form.boatprice;
      }
    },
    watch:{
      visible: function(val){
        if(val){
          this.activeTab = 'detail';
          this.initDialog(this.colid,this.colno);
        }
      }
    },
    mounted: function(){
      this.$refs.nameinput.focus();
      select_data({"keyname":"subchannel,ratecode,payment,block,market,source,restype,productkit"}).then(response => {
          this.subchannels = select_res(response.data,"subchannel");
          this.ratecodes = select_res(response.data,"ratecode");
          this.payments = select_res(response.data,"payment");
          this.blocks = select_res(response.data,"block");
          this.markets = select_res(response.data,"market");
          this.scodes = select_res(response.data,"source");
          this.restypes = select_res(response.data,"restype");
          this.orgkits = select_res(response.data,"productkit");
          this.initDialog(this.colid,this.colno);
      });
    },
    methods: {
      initDialog(id,colno){
        load_col({"sqlid":id,"colno":colno,"history":this.history,"isgroup":this.isgroup}).then(response => {
          this.form = response.data;
          this.kits = filterOnlyValue(this.orgkits, this.form.packagecode);
        }).catch(() => {
          this.$emit('update:visible',false);
        });
      },
      selectProfile(selectValue){
        this.showProfileDialog = false;
        this.form.profileid = selectValue.profileid;
        this.form.name = selectValue.name;
        this.form.telephone = selectValue.mobile;
        this.form.idnumber = selectValue.idnum;
      },
      tableSummary(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计';
            return;
          }
          if (column.property=='amount') {
            const values = data.map(function(item){
              return Number(item[column.property]);
            });
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          }
        });
        return sums;
      },
      roomfeeFormatter(row){
        if(row.isroomfee){
          return '是';
        }
        return '';
      },
      checkNameExist(){
        if(this.form.profileid==''&&this.form.name.length>0){
          name_exist({"name":this.form.name,"type":this.form.isgroup?'G':''}).then(response => {
            if(response.data){
              this.showProfileDialog = true;
            }
          })
        }
      },
      // loadSubTable(){
      //   if(this.activeTab=='prepay'){
      //     this.$refs.paylist.loadTable();
      //   }else if(this.activeTab=='room'){
      //     this.$refs.roomlist.loadTable();
      //   }else if(this.activeTab=='ticket'){
      //     this.$refs.ticketlist.loadTable();
      //   }else if(this.activeTab=='catering'){
      //     this.$refs.cateringlist.loadTable();
      //   }else if(this.activeTab=='meeting'){
      //     this.$refs.meetinglist.loadTable();
      //   }else if(this.activeTab=='guide'){
      //     this.$refs.guidelist.loadTable();
      //   }else if(this.activeTab=='spa'){
      //     this.$refs.spalist.loadTable();
      //   }else if(this.activeTab=='boat'){
      //     this.$refs.boatlist.loadTable();
      //   }else if(this.activeTab=='fixcharge'){
      //     this.$refs.fixchargelist.loadTable();
      //   }else if(this.activeTab=='discount'){
      //     this.$refs.discountlist.loadTable();
      //   }
      // },
      refresh(){//刷新的时候也把col_rs刷一下
        this.initDialog(this.form.sqlid,this.colno);
        // this.loadSubTable();
      },
      handleSave(){
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.buttonLoading = true;
            save_col(this.form).then(response => {
              this.form = response.data;
              this.$message({ message: '保存成功!', type: 'success'});
            }).finally(()=>{
              this.buttonLoading = false;
            });
          } else {
            return false;
          }
        });
      },
      handleCancel(){
        this.cancelForm.sqlid = this.form.sqlid;
        this.showCancelDialog = true;
      },
      doCancel(){
        this.buttonLoading = true;
        cancel_col(this.cancelForm).then(() => {
          this.showCancelDialog = false;
          this.$emit('update:visible',false);
          this.$message({ message: '取消成功!', type: 'success'});
        }).finally(()=>{
          this.buttonLoading = false;
        })
      },
      // handleSubNew(){
      //   if(this.activeTab=='ticket'){
      //     this.showTicketDetailDialog = true;
      //   }else if(this.activeTab=='prepay'){
      //     this.showPayDetailDialog = true;
      //   }else if(this.activeTab=='catering'){
      //     this.showCateringDetailDialog = true;
      //   }else if(this.activeTab=='room'){
      //     this.showRoomDetailDialog = true;
      //   }else if(this.activeTab=='meeting'){
      //     this.showMeetingDetailDialog = true;
      //   }else if(this.activeTab=='guide'){
      //     this.showGuideDetailDialog = true;
      //   }else if(this.activeTab=='spa'){
      //     this.showSpaDetailDialog = true;
      //   }else if(this.activeTab=='boat'){
      //     this.showBoatDetailDialog = true;
      //   }
      // },
      handleChecked(){
        this.$confirm('该订单已核销完成?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
          finish_col({"sqlid":this.form.sqlid}).then(response => {
            this.refresh();
            this.$message({ message: '订单核销完成!', type: 'success'});
          });
        }).catch(() => {});
      },
      handlePrepare(){
        this.$confirm('预付款,子订单价格状态不正确执行修复?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
          this.buttonLoading = true;
          refresh_col({"sqlid":this.form.sqlid}).then(response => {
            this.form = response.data;
            this.$message({ message: '数据修复成功!', type: 'success'});
          }).finally(()=>{
            this.buttonLoading = false;
          });
        }).catch(() => {});
      },
      handleCommand(command) {
        if(command==='U'){
          this.showLogDialog = true;
        }else{
          this.showInterfaceLogDialog = true;
        }
      },
      getAccountData(){
        this.loadingAccount = true;
        load_account({'pmscolno':this.form.pmscolno}).then(response => {
          this.accountData = response.data;
        }).finally(() => { this.loadingAccount = false;})
      }
    }
}
</script>

<style scoped>

</style>
